export const getInstanceId = (): string => {
  const existingId = sessionStorage.getItem("x-yapi-webapp-instance-id");
  if (existingId) return existingId;

  const newId = crypto.randomUUID();
  sessionStorage.setItem("x-yapi-webapp-instance-id", newId);
  return newId;
};

export const getRequestId = (): string => crypto.randomUUID();
