export const paths = {
  root: "/",
  viewer: "/viewer/:formData",
  welcomePage: "/welcome",
  preLoginPage: "/forms-request",
  loginPage: "/auth-forms-request",
  thankYouPage: "/thank-you",
  logoutPage: "/logout",
  expiredLinkPage: "/expired-link",
  registerDevice: "/register-device",
  deactivatedUrl: "/deactivated-url",
  qrCodeFormsHandlerPage: "/fill-forms",
};
