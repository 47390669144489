import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQueryWithReauth } from "../baseQueryWithReauth";
import { PRACTICE_ID_HEADER_NAME } from "../constants/api.constant";
import { OAuthGrantType } from "../enums/EOAuthGrantType.enum";
import { ITokenResponse } from "../interfaces/ITokenResponse.interface";

type PatientCredentialsTokenParams = {
  readonly practiceId: string;
  readonly patientId: string;
  readonly formAssignmentId: string;
  readonly formAssignmentNotificationId?: string;
  readonly serverSignature: string;
  readonly dateOfBirth: string;
};

// Device authentication assumes that a patient has already verified their postalCode and dateOfBirth
type DeviceCredentialsTokenParams = Omit<
  PatientCredentialsTokenParams,
  "dateOfBirth"
> & {
  readonly deviceSecret: string;
  readonly deviceId: string;
};

type RefreshTokenParams = {
  readonly refresh_token: string;
};

type LogoutParams = {
  readonly accessToken: string;
};

export const oauthApi = createApi({
  reducerPath: "oauthApi",
  baseQuery: baseQueryWithReauth(
    `${process.env.REACT_APP_API_HOST}api/v1/oauth`
  ),
  endpoints: (build) => ({
    tokenPatientCredentials: build.mutation<
      ITokenResponse,
      PatientCredentialsTokenParams
    >({
      query: (params) => ({
        url: "token",
        method: "POST",
        body: JSON.stringify({
          grant_type: OAuthGrantType.PatientCredentials,
          ...params,
        }),
        headers: [[PRACTICE_ID_HEADER_NAME, params.practiceId]],
      }),
    }),
    tokenQRCodeCredentials: build.mutation<
      ITokenResponse,
      {
        practiceId: string;
        qrCodeId: string;
        patientId: string;
        formAssignmentId: string;
        formAssignmentNotificationId?: string;
        qrCodeSecret: string;
        serverSignature: string;
      }
    >({
      query: (params) => ({
        url: "token",
        method: "POST",
        body: JSON.stringify({
          grant_type: OAuthGrantType.QRCodeCredentials,
          ...params,
        }),
        headers: [[PRACTICE_ID_HEADER_NAME, params.practiceId]],
      }),
    }),
    tokenDeviceCredentials: build.mutation<
      ITokenResponse,
      DeviceCredentialsTokenParams
    >({
      query: (params) => ({
        url: "token",
        method: "POST",
        body: JSON.stringify({
          grant_type: OAuthGrantType.DeviceCredentials,
          ...params,
        }),
        headers: [[PRACTICE_ID_HEADER_NAME, params.practiceId]],
      }),
    }),
    refreshToken: build.mutation<ITokenResponse, RefreshTokenParams>({
      query: (params) => ({
        url: "token",
        method: "POST",
        body: JSON.stringify({
          grant_type: OAuthGrantType.RefreshToken,
          ...params,
        }),
      }),
    }),
    logout: build.mutation<void, LogoutParams>({
      query: () => ({
        url: "logout",
        method: "POST",
      }),
    }),
  }),
});

export const {
  useTokenPatientCredentialsMutation,
  useTokenQRCodeCredentialsMutation,
  useRefreshTokenMutation,
  useTokenDeviceCredentialsMutation,
  useLogoutMutation,
} = oauthApi;
