import { createSlice } from "@reduxjs/toolkit";

import { IFamilyMemberData } from "../../app/interfaces/IFamilyMember.interface";
import { RootState } from "../../app/store/store";

interface AppIdsSlice {
  familyMembersIds: {
    formAssignmentId?: string;
    patientId?: string;
    practiceId: string;
    formAssignmentNotificationId: string;
  }[];
  familyMembers: IFamilyMemberData[];
  practiceId: string | null;
}

const initialState: AppIdsSlice = {
  familyMembersIds: [
    {
      formAssignmentId: null,
      patientId: null,
      practiceId: null,
      formAssignmentNotificationId: null,
    },
  ],
  familyMembers: [],
  practiceId: null,
};

const appIdsSlice = createSlice({
  name: "appIdsSlice",
  initialState,
  reducers: {
    savePracticeId: (state, action) => {
      state.practiceId = action.payload;
    },
    saveFamilyMembersIds: (state, action) => {
      if (action.payload?.length === 1) {
        state.familyMembersIds[0] = {
          ...state.familyMembersIds[0],
          ...action.payload[0],
        };
      } else {
        state.familyMembersIds = action.payload;
      }
    },
    resetFamilyMembersIds: (state) => {
      state.familyMembersIds = [
        {
          formAssignmentId: null,
          patientId: null,
          practiceId: null,
          formAssignmentNotificationId: null,
        },
      ];
    },
    saveFamilyMember: (state, action) => {
      const index = state.familyMembers.findIndex(
        (member) => member.patientId === action.payload.patientId
      );
      if (index !== -1) {
        state.familyMembers[index] = {
          ...state.familyMembers[index],
          ...action.payload,
        };
      } else {
        state.familyMembers = [...state.familyMembers, action.payload];
      }
    },

    resetIdsExpectPatientId: (state) => {
      state.familyMembersIds[0] = {
        ...state.familyMembersIds[0],
        formAssignmentId: "",
        patientId: "",
      };
    },
    resetFamilyMembers: (state) => {
      state.familyMembers = [];
    },
    reset: () => initialState,
  },
});
export const selectMembersPracticeId = (state: RootState) =>
  state.appIdsSlice.practiceId;
export const selectFamilyMembersIds = (state: RootState) =>
  state.appIdsSlice.familyMembersIds;
export const selectFamilyMembers = (state: RootState) =>
  state.appIdsSlice.familyMembers;

export const {
  savePracticeId,
  resetIdsExpectPatientId,
  reset,
  resetFamilyMembers,
  saveFamilyMember,
  saveFamilyMembersIds,
  resetFamilyMembersIds,
} = appIdsSlice.actions;
export default appIdsSlice.reducer;
